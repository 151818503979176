import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";

const HeaderApp = props => {

    const [pessoa, setPessoa] = useState({});
    const [allowed, setAllowed] = useState([]);
    const [carteirinha, setCarteirinha] = useState({});
    const [isNotification, setIsNotification] = useState(false);



    const location = useLocation();
    const [show, setShow] = useState(false);
    const [tabKey, setTabKey] = useState('frente');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);





    useEffect(() => {
        if (!props) return false;
        const pessoa = props?.pessoa || {};
        const modulo = props?.modulo || {};
        if (!pessoa?.fileIcon) pessoa.fileIcon = '/default-avatar.png';
        if (pessoa?.nome) pessoa.nome = pessoa.nome.split(' ').shift();
        setPessoa({ ...pessoa });

        if (props?.carteirinha) setCarteirinha({ ...props.carteirinha });
        if (props?.allowed) setAllowed([...props.allowed]);

        if (modulo.chat || modulo.push || modulo.portal_mail) {
            setIsNotification(true);
        } else {
            setIsNotification(false);
        }
    }, [props]);

    return <>
        {
            location.pathname === "/" ? (
                <HeaderAppHome
                    {...props}
                    isNotification={isNotification}
                    pessoa={pessoa}
                    handleShow={allowed.includes('/meu-cartao') ? handleShow : false}
                />
            ) : (
                <HeaderAppSingle
                    {...props}
                    isNotification={isNotification}
                    handleShow={allowed.includes('/meu-cartao') ? handleShow : false}
                />
            )
        }

        <Modal
            show={show}
            onHide={handleClose}
            fullscreen={false}
            backdrop="static"
            dialogClassName="modal-card-filiado"
        >
            <Modal.Header closeButton>
                <Modal.Title>Identificação Pessoal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    id="card-filiado"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                    variant="underline"
                    className="mb-3"
                    justify
                >
                    <Tab eventKey="frente" title="Frente">
                        <div className="img-thumbnail card-filiado-frente" style={{
                            background: `url(${carteirinha?.card_frente})`,
                        }}>
                            <div className="card-container">
                                <div className="matricula">
                                    <small className="card-label d-block">Matrícula</small>
                                    <span className="card-value">{carteirinha?.matricula}</span>
                                </div>
                                <div className="nome">
                                    <small className="card-label d-block">Nome</small>
                                    <span className="card-value">{carteirinha?.filiado}</span>
                                </div>
                                <div className="situacao">
                                    <small className="card-label d-block">Cadastro</small>
                                    <span className="card-value">{carteirinha?.situacao}</span>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="verso" title="Verso">
                        <div className="img-thumbnail card-filiado-verso" style={{
                            background: `url(${carteirinha?.card_verso})`,
                        }}>
                            <div className="card-container">
                                <div className="matricula">
                                    <small className="card-label d-block">Matrícula</small>
                                    <span className="card-value">{carteirinha?.matricula}</span>
                                </div>
                                <div className="nome">
                                    <small className="card-label d-block">Nome</small>
                                    <span className="card-value">{carteirinha?.filiado}</span>
                                </div>
                                <div className="situacao">
                                    <small className="card-label d-block">Cadastro</small>
                                    <span className="card-value tipo">{carteirinha?.tipo}</span>
                                    <span className="card-value divisor"> - </span>
                                    <span className="card-value situacao">{carteirinha?.situacao}</span>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
        <style dangerouslySetInnerHTML={{
            __html: `${carteirinha.css}`,
        }}></style>
    </>

}


const HeaderAppHome = props => {

    const pessoa = props.pessoa;
    const isNotification = props?.isNotification || false;

    return (
        <>
            <div className=" text-body py-2 bg-light header">
                <Container>
                    <Row className="justify-content-between align-items-center flex-nowrap">
                        <Col className="col-auto">
                            <Row className="align-items-center flex-nowrap">
                                <Col className="pe-0 col-auto">
                                    <Link to={'/'}>
                                        <img src={pessoa?.fileIcon} alt={pessoa?.nome} className="img-fluid rounded-circle" style={{ "maxHeight": "40px" }} />
                                    </Link>
                                </Col>
                                <Col className="col-auto">
                                    <div className="fw-medium">Olá, {pessoa?.apelido ? pessoa.apelido : pessoa?.nome}</div>
                                    <Link className="d-block" to={'/meu-perfil'}>
                                        <small>Perfil e configurações</small> <i className="las la-angle-right"></i>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-auto">
                            <Row className="align-items-center">
                                {
                                    props.handleShow ? (
                                        <Col className="col-auto ps-0">
                                            <Link onClick={props.handleShow} className="fs-1 text-body">
                                                <i className="las la-id-card"></i>
                                            </Link>
                                        </Col>
                                    ) : (<></>)
                                }
                                {
                                    isNotification ? (
                                        <Col className="col-auto ps-0">
                                            <Button className="btn-light btn-notificacao p-0 fs-2">
                                                <i className="las la-bell"></i>
                                            </Button>
                                        </Col>
                                    ) : (<></>)
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

const HeaderAppSingle = props => {
    const empresa = props.empresa || {};
    const navigate = useNavigate();
    const isNotification = props?.isNotification || false;

    return (
        <>
            <div className=" text-body py-2 bg-light header">
                <Container>
                    <Row className="justify-content-between align-items-center flex-nowrap">
                        <Col className="col-auto">
                            <Button className="btn btn-link text-body bg-transparent text-decoration-none p-0 border-0" onClick={() => navigate(-1)}>
                                <i className="las la-arrow-left"></i> Voltar
                            </Button>
                        </Col>
                        <Col className="col-auto">
                            {empresa.nome_fantasia}
                        </Col>
                        <Col className="col-auto">
                            <Row className="align-items-center">
                                {
                                    props.handleShow ? (
                                        <Col className="col-auto ps-0">
                                            <Link onClick={props.handleShow} className="fs-1 text-body">
                                                <i className="las la-id-card"></i>
                                            </Link>
                                        </Col>
                                    ) : (<></>)
                                }
                                {
                                    isNotification ? (
                                        <Col className="col-auto ps-0">
                                            <Button className="btn-light btn-notificacao p-0 fs-2">
                                                <i className="las la-bell"></i>
                                            </Button>
                                        </Col>
                                    ) : (<></>)
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}



export default HeaderApp;